@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.price,
.fa-num {
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}

tr[role="row"] {
  th {
    &:first-child {
      @apply rounded-r-lg;
    }
    &:last-child {
      @apply rounded-l-lg;
    }
  }
  &:first-child {
    td {
      &:first-child {
        @apply border-gray-200;

        @apply rounded-tr-lg;
      }
      &:last-child {
        @apply border-gray-200;

        @apply rounded-tl-lg;
      }
    }
  }
  &:last-child {
    td {
      &:first-child {
        @apply border-gray-200;

        @apply rounded-br-lg;
      }
      &:last-child {
        @apply border-gray-200;

        @apply rounded-bl-lg;
      }
    }
  }
}

table tbody:before {
  content: "@";
  display: block;
  line-height: 20px;
  text-indent: -99999px;
  background: #181717;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.add-dots {
  li {
    &:after {
      content: "";
      width: 6px;
      height: 6px;
      @apply rounded-full bg-light-gray relative inline-flex mr-4;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.gradient {
  background: linear-gradient(to right, #fcd842, #fcd842);
}

.p-2px {
  padding: 2px;
}

.svg-pi {
  width: 50px;
  height: 50px;
  transform: rotate(-90deg); /* Fix the orientation */
}

.svg-pi-track {
  stroke: #ddd;
}

.svg-pi-indicator {
  stroke-dasharray: 125.6px; /* DashArray = Track's or indicator's Circumference */
  stroke-linecap: round;
}

.svg-pi-track,
.svg-pi-indicator {
  cx: 25px;
  cy: 25px;
  r: 20px;
  fill: transparent;
  stroke-width: 6px;
}

// .svg-pi-25 .svg-pi-indicator {
//   stroke-dashoffset: 188.4px; /* DashOffset = DashArray * ((100 - progress)/100) */
// }
